import { FluidObject } from 'gatsby-image';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const bPillModalSuccessIcon = require('../../../assets/images/b-pill-modal-success.png');
const bPillModalDefaultIcon = require('../../../assets/images/b-pill-modal-default.png');

export interface BirdiModalContentProps {
    icon: 'none' | 'alert' | 'success' | 'default';
    title?: string;
    subTitle?: JSX.Element | string;
    body: JSX.Element | string;
}

function iconMap(icon: string): JSX.Element {
    switch (icon) {
        case 'alert':
            return (
                <svg width="66px" height="66px" viewBox="0 0 66 66" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="All-pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Alert-States" transform="translate(-687.000000, -2778.000000)">
                            <g id="Group-6" transform="translate(270.000000, 2721.000000)">
                                <g id="Group-5" transform="translate(420.000000, 60.000000)">
                                    <circle
                                        id="Oval"
                                        stroke="#F04C34"
                                        strokeWidth="3"
                                        fill="#F04C34"
                                        cx="30"
                                        cy="30"
                                        r="31.5"
                                    ></circle>
                                    <g id="Group" transform="translate(13.000000, 11.000000)">
                                        <path
                                            d="M18.8475007,3.59433458 L31.9212055,27.4175301 C32.5190372,28.5069122 32.1205568,29.8746691 31.0311748,30.4725007 C30.6994283,30.6545567 30.3271228,30.75 29.9487049,30.75 L3.80129513,30.75 C2.55865445,30.75 1.55129513,29.7426407 1.55129513,28.5 C1.55129513,28.1215821 1.64673846,27.7492766 1.82879445,27.4175301 L14.9024993,3.59433458 C15.5003309,2.50495253 16.8680878,2.10647217 17.9574699,2.70430378 C18.3327786,2.91026586 18.6415386,3.21902591 18.8475007,3.59433458 Z"
                                            id="Triangle"
                                            stroke="#FFFFFF"
                                            strokeWidth="2.25"
                                        ></path>
                                        <path
                                            d="M17.1479762,21.75 C18.1328295,21.75 18.3790428,21.5401119 18.4282855,20.7005597 L18.7483628,11.5494403 C18.7729841,10.7098881 18.5267708,10.5 17.5419175,10.5 L16.1877442,10.5 C15.2028909,10.5 14.9566776,10.7098881 15.0059203,11.5494403 L15.3013763,20.7005597 C15.3506189,21.5401119 15.5968323,21.75 16.5816856,21.75 L17.1479762,21.75 Z M16.875,27 C18.0925325,27 18.75,26.2105263 18.75,25.1496711 C18.75,24.1381579 18.0925325,23.25 16.875,23.25 C15.6331169,23.25 15,24.1381579 15,25.1496711 C15,26.2105263 15.6331169,27 16.875,27 Z"
                                            id="!"
                                            fill="#FFFFFF"
                                            fillRule="nonzero"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case 'success':
            return (
                <img width={76} src={bPillModalSuccessIcon} alt={'Success Modal Icon'} title={'Success Modal Icon'} />
            );
        case 'default':
            return (
                <img width={76} src={bPillModalDefaultIcon} alt={'Default Modal Icon'} title={'Default Modal Icon'} />
            );
        default:
            return <></>;
    }
}

export const OverflowModal = ({
    image,
    title,
    text,
    internalCtas,
    warningText
}: {
    image: FluidObject;
    title: string;
    text: string;
    internalCtas?: Object[];
    warningText?: string;
}) => {
    return (
        <BirdiModalContent
            icon="none"
            body={
                <div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <GatsbyImage image={getImage(image)} alt={''} className="overflow-modal-image" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="d-flex flex-column justify-content-center">
                            <div className="overflow-modal-content d-flex flex-column justify-content-center align-items-center align-items-lg-start">
                                <h1 className="text-center">{title}</h1>
                                <div className="spacer" />
                                <div className="modal-body-string" />
                                <p
                                    className="text-center text-lg-left mb-4 h4"
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                                {warningText && <Disclaimer disclaimerText={warningText} />}
                                {internalCtas?.map((cta, idx) => (
                                    <Button
                                        className="mt-3 w-100"
                                        key={`internal-cta-${idx}`}
                                        {...cta}
                                        type={'button'}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        />
    );
};

const BirdiModalContent = React.memo(({ icon, title, subTitle, body }: BirdiModalContentProps) => (
    <div className={'d-flex flex-column align-items-center text-center'}>
        <div className="my-3">{iconMap(icon)}</div>
        {title && (
            <>
                <h1>{title}</h1>
                <div className="spacer" />
            </>
        )}
        {typeof subTitle === 'string' ? (
            <div className={subTitle ? 'mb-5' : ''} dangerouslySetInnerHTML={{ __html: subTitle }} />
        ) : (
            <div className={subTitle ? 'mb-5' : ''}>{subTitle}</div>
        )}
        {typeof body === 'string' ? (
            <div className="modal-body-string" dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
            <div className="modal-body-content w-100">{body}</div>
        )}
    </div>
));
export default BirdiModalContent;
